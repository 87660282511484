import { useEffect } from "react";
import MainWrapper from "../components/mainWrapper";

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact Us - Suraksha Check";
  }, []);

  return (
    <div className="contact-us">
      <MainWrapper className="contact-wrapper">
        <h1>Contact Us</h1>
        <p>
          Thank you for your interest in reaching out to us at SurakshaCheck.
          Please use the form below to send us your inquiries, feedback, or
          requests, and we'll get back to you promptly.
        </p>
        <p>
          Feel free to contact us directly using the information provided below:
        </p>
        <form
          action="https://formsubmit.co/helpmeallexis@gmail.com"
          method="POST"
        >
          <section>
            <input placeholder="First Name" name="firstName" required />
            <input placeholder="Last Name" name="lastName" required />
          </section>
          <input placeholder="Email ID" name="email" required type="email" />
          <textarea placeholder="Message" name="message" required />
          <button>SEND</button>
        </form>
        <p>
          Please specify your inquiry or reason for contacting us in the message
          field above. Whether you have questions about our services, need
          assistance, or want to discuss potential collaborations, we're here to
          assist you. We look forward to hearing from you and assisting with
          your cyber security needs!
        </p>
      </MainWrapper>
    </div>
  );
};

export default ContactUs;
