import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Search from "./pages/search";
import AboutUs from "./pages/aboutUs";
import ContactUs from "./pages/contactUs";
import Signup from "./pages/signup";
import Signin from "./pages/signin";
import VerifyEmail from "./pages/verifyEmail";
import Loader from "./components/loader";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Credits from "./pages/credits";

const Navigation = () => {
  const showLoader = useSelector((s) => s.loader.value);

  const token = useSelector((s) => s.token.value);

  return (
    <BrowserRouter>
      {showLoader && (
        <div className="loader-cont">
          <Loader show />
        </div>
      )}
      <ToastContainer autoClose={2500} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search/:q" element={<Search />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
