import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
  name: "token",
  initialState: {
    value: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setToken } = tokenSlice.actions;
export const tokenReducer = tokenSlice.reducer;

const userSlice = createSlice({
  name: "user",
  initialState: {
    value: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;

const loaderSlice = createSlice({
  name: "loader",
  initialState: {
    value: false,
  },
  reducers: {
    setLoader: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setLoader } = loaderSlice.actions;
export const loaderReducer = loaderSlice.reducer;

const creditsSlice = createSlice({
  name: "credits",
  initialState: {
    value: 0,
  },
  reducers: {
    setCredits: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCredits } = creditsSlice.actions;
export const creditsReducer = creditsSlice.reducer;
