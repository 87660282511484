import { useEffect } from "react";
import MainWrapper from "../components/mainWrapper";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - Suraksha Check";
  }, []);

  return (
    <div className="about-us">
      <MainWrapper>
        <h1>About Suraksha Check</h1>
        <p>
          At Suraksha Check, we are committed to empowering users with tools and
          knowledge to safeguard their digital presence. Our platform allows
          users to check if their passwords have been compromised in any known
          data breaches, ensuring proactive steps towards online security.
        </p>
        <p>
          Explore our resources to learn about cyber awareness and best
          practices for cyber safety. We provide valuable insights and tips to
          help you navigate the digital landscape securely.
        </p>
        <p>
          Join us in the journey towards a safer and more informed online
          community. Together, let's enhance cyber awareness and protect our
          digital identities.
        </p>
        <p className="p">Stay safe, stay secure with Suraksha Check.</p>
      </MainWrapper>
    </div>
  );
};

export default AboutUs;
