import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../functios/api";
import toast from "../functios/toast";

const VerifyEmail = () => {
  const { token } = useParams();

  const navigate = useNavigate();

  const verify = async () => {
    try {
      await api.post("/verify-email", { token });
      toast.success("Email verified, Now you can signin");
      navigate("/signin");
    } catch (err) {
      console.log("🚀 ~ file: verifyEmail.js:11 ~ verify ~ err:", err);
    }
  };

  useEffect(() => {
    verify();
  }, []);

  return <></>;
};

export default VerifyEmail;
