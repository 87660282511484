import images from "../constants/images";
import Header from "./header";

const MainWrapper = ({ children, className }) => {
  return (
    <div
      className="main-wrapper"
      // style={{
      //   backgroundImage: `linear-gradient(rgba(0,0,0,0.9) 100%, #000)`,
      // }}
    >
      <Header />
      <div className={"content " + className}>{children}</div>
    </div>
  );
};

export default MainWrapper;
