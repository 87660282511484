import Clickable from "./clickable";

const SearchBar = ({ onSubmit = () => null, onChange = () => null, value }) => {
  return (
    <div className="search-bar">
      <input
        placeholder="Enter Email, Mobile Number, UPI ID, Domain, Password, and More..."
        onChange={(e) => onChange(e.currentTarget.value)}
        value={value}
        spellCheck={false}
      />
      <Clickable className="search-btn" disabled={!value} onClick={onSubmit}>
        Search
      </Clickable>
    </div>
  );
};

export default SearchBar;
