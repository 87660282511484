import { toast as Toast } from "react-toastify";

const toast = {
  success: (msg) => {
    Toast.success(msg, {
      position: "top-center",
    });
  },
  error: (msg) => {
    Toast.error(msg, {
      position: "top-center",
    });
  },
};

export default toast;
