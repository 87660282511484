import { useEffect, useState } from "react";
import MainWrapper from "../components/mainWrapper";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/searchBar";
import { useSelector } from "react-redux";

const Home = () => {
  const [q, setQ] = useState("");

  const navigate = useNavigate();

  const token = useSelector((s) => s.token.value);

  useEffect(() => {
    document.title = "Suraksha Check";
  }, []);

  return (
    <div className="home">
      <MainWrapper className="home-wrapper">
        <div className="h1">
          <span>Suraksha Check</span>
          Protect your sensitive information and proactively defend against data
          breaches.
        </div>
        <p>
          This cutting-edge cybersecurity tool is your essential resource,
          offering the fastest and most thorough data breach search engine,
          scanning through a massive database of 62,263,913,190 records. Stay
          secure and stay informed with Suraksha Check.
        </p>
        <SearchBar
          onChange={setQ}
          onSubmit={() => {
            if (!token) return navigate("/signin");
            navigate("/search/" + q);
          }}
          value={q}
        />
        <footer>
          <span>
            Empowering Innovation, Building Excellence <br />©{" "}
            {new Date().getFullYear()} Made with ❤️ in India.
            <br /> Powered by ~卂ㄥㄥ乇乂丨丂
          </span>
          {/* <div className="social">
            <a href="#">
              <i className="material-icons">twitter</i>
            </a>
          </div> */}
        </footer>
      </MainWrapper>
    </div>
  );
};

export default Home;
