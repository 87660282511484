import { ThreeDots } from "react-loader-spinner";

const Loader = ({ show }) =>
  show ? (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <ThreeDots
        visible
        height="100"
        width="100"
        color="#fff"
        radius="9"
        ariaLabel="three-dots-loading"
      />
    </div>
  ) : (
    <></>
  );

export default Loader;
