import { Provider } from "react-redux";
import Navigation from "./navigation";
import { PersistGate } from "redux-persist/integration/react";
import { pStore, store } from "./redux/store";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={pStore}>
        <Navigation />
      </PersistGate>
    </Provider>
  );
};

export default App;
