import { persistStore, persistReducer } from "redux-persist";
import {
  creditsReducer,
  loaderReducer,
  tokenReducer,
  userReducer,
} from "./slices";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: "user",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "m£c|^v~l_aa0vC381H@Y8OGh£{[np60nN$!}5qE)d",
      onError: function (error) {
        console.log("🚀 ~ error:", error);
      },
    }),
  ],
};

const rootReducer = combineReducers({
  token: tokenReducer,
  user: userReducer,
  loader: loaderReducer,
  credits: creditsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false,
    }).concat([]),
});

export const pStore = persistStore(store);
