import { Link, NavLink, useNavigate } from "react-router-dom";
import images from "../constants/images";
import Clickable from "./clickable";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../redux/slices";

const Header = () => {
  const [navHeight, setNavHeight] = useState(0);

  const token = useSelector((s) => s.token.value);
  const credits = useSelector((s) => s.credits.value);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const width = document.body.offsetWidth;

  return (
    <>
      {width >= 820 ? (
        <div className="header">
          <Link to="/" className="logo">
            <img src={images.logo} />
          </Link>
          <div className="navs">
            <NavLink
              className={({ isActive }) => `nav${isActive ? " open" : ""}`}
              to="/"
            >
              HOME
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav${isActive ? " open" : ""}`}
              to="/about-us"
            >
              ABOUT US
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav${isActive ? " open" : ""}`}
              to="/credits"
            >
              CREDITS ({credits})
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav${isActive ? " open" : ""}`}
              to="/contact-us"
            >
              CONTACT US
            </NavLink>
            {!token ? (
              <Link to="/signin" className="login-btn">
                SIGN IN
              </Link>
            ) : (
              <div
                className="login-btn"
                onClick={() => {
                  dispatch(setToken(null));
                  localStorage.clear();
                  navigate("/signin");
                }}
              >
                LOGOUT
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="mobile-header">
            <Clickable
              className="menu-btn"
              onClick={() => setNavHeight(navHeight === 0 ? 220 : 0)}
            >
              <img src={images.menu} />
            </Clickable>
            <Link to="/">
              <img src={images.logo} className="logo" />
            </Link>
          </div>
          <div className="mobile-nav" style={{ height: navHeight }}>
            <NavLink
              className={({ isActive }) => `nav${isActive ? " open" : ""}`}
              to="/"
            >
              HOME
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav${isActive ? " open" : ""}`}
              to="/about-us"
            >
              ABOUT US
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav${isActive ? " open" : ""}`}
              to="/credits"
            >
              CREDITS
            </NavLink>
            <NavLink
              className={({ isActive }) => `nav${isActive ? " open" : ""}`}
              to="/contact-us"
            >
              CONTACT US
            </NavLink>
            {!token ? (
              <Link to="/signin" className="login-btn">
                SIGN IN
              </Link>
            ) : (
              <div
                className="login-btn"
                onClick={() => {
                  dispatch(setToken(null));
                  localStorage.clear();
                  navigate("/signin");
                }}
              >
                LOGOUT
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Header;
