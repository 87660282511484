import { useEffect } from "react";
import Clickable from "../components/clickable";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../functios/api";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/slices";

const Signin = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const initialValues = {
    emailID: null,
    password: null,
  };

  const validationSchema = yup.object({
    emailID: yup
      .string()
      .required("Email ID cannot be empty")
      .matches(
        /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        "Invalid Email ID"
      ),
    password: yup.string().required("Password cannot be empty"),
  });

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema,
      validateOnBlur: true,
      onSubmit: () => signin(),
    });

  const signin = async () => {
    try {
      const { token } = await api.post("/signin", {
        emailID: values.emailID,
        password: values.password,
      });
      dispatch(setToken(token));
      navigate("/");
    } catch (err) {
      console.log("🚀 ~ file: signup.js:60 ~ signup ~ err:", err);
    }
  };

  useEffect(() => {
    document.title = "Signin - Suraksha Check";
  }, []);

  return (
    <div className="signin signup">
      <main>
        <h1>SIGN IN</h1>

        <input
          placeholder="Email ID"
          type="email"
          autoComplete={false}
          onChange={handleChange("emailID")}
          onBlur={handleBlur("emailID")}
          value={values.emailID}
        />
        {errors.emailID && touched.emailID && (
          <pre className="err">{errors.emailID}</pre>
        )}

        <input
          placeholder="Password"
          type="password"
          autoComplete={false}
          onChange={handleChange("password")}
          onBlur={handleBlur("password")}
          value={values.password}
        />
        {errors.password && touched.password && (
          <pre className="err">{errors.password}</pre>
        )}

        <div className="info">
          Don't have a account? <Link to="/signup">SIGN UP</Link>
        </div>
        <Clickable className="btn" onClick={handleSubmit}>
          SIGN IN
        </Clickable>
      </main>
    </div>
  );
};

export default Signin;
