import { useNavigate, useParams } from "react-router-dom";
import MainWrapper from "../components/mainWrapper";
import { useEffect, useState } from "react";
import api from "../functios/api";
import SearchBar from "../components/searchBar";
import Loader from "../components/loader";
import { useDispatch } from "react-redux";
import { setCredits } from "../redux/slices";

const Search = () => {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { q } = useParams();

  const parseKey = (str) => str.replace(/([a-z])([A-Z])/g, "$1 $2");

  const searchData = async () => {
    try {
      let { list: data, credits } = await api.get("/search/" + q);
      data = data.sort((a, b) => a.source.localeCompare(b.source));
      setData(data);
      dispatch(setCredits(credits));
    } catch (err) {
      console.log("🚀 ~ file: search.js:14 ~ searchData ~ err:", err);
      navigate("/");
    }
  };

  useEffect(() => {
    setData(null);
    setSearch(q);
    searchData();
  }, [q]);

  return (
    <div className="search">
      <MainWrapper className="search-wrapper">
        <br />
        <SearchBar
          onChange={setSearch}
          onSubmit={() => navigate("/search/" + search, { replace: true })}
          value={search}
        />
        <br />
        <br />
        {data &&
          data.map((item, index) => {
            const { source } = item;
            return item.data.map((item2, index2) => {
              const keys = Object.keys(item2);
              return (
                <div className="card">
                  <div className="source">{source}</div>
                  {keys.map((item) => (
                    <div className="row">
                      <div className="col-1">
                        {/* <i class="material-icons">
                          {item.toLowerCase().split("(")[0]}
                        </i> */}
                        {parseKey(item)}
                      </div>
                      <div className="col-2">{item2[item]}</div>
                    </div>
                  ))}
                </div>
              );
            });
          })}
      </MainWrapper>
    </div>
  );
};

export default Search;
