import { useEffect } from "react";
import Clickable from "../components/clickable";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../functios/api";
import toast from "../functios/toast";

const Signup = () => {
  const navigate = useNavigate();

  const initialValues = {
    fullName: null,
    emailID: null,
    password: null,
    rPassword: null,
  };

  const validationSchema = yup.object({
    fullName: yup
      .string()
      .required("Full Name cannot be empty")
      .matches(/^[a-zA-Z\s]+$/, "Invalid Full Name"),
    emailID: yup
      .string()
      .required("Email ID cannot be empty")
      .matches(
        /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        "Invalid Email ID"
      ),
    password: yup
      .string()
      .required("Password cannot be empty")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        `Your password must have:
  - Between 8-16 characters
  - Uppercase characters A-Z
  - Lowercase characters a-z
  - Numbers (0-9)
  - Special characters ! @ # $ % & ^ ( ) _ - + =; : ? / , .`
      ),
    rPassword: yup
      .string()
      .required("Confirm Password cannot be empty")
      .oneOf(
        [yup.ref("password"), null],
        "Passwords & Confirm Password do not match"
      ),
  });

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema,
      validateOnBlur: true,
      onSubmit: () => signup(),
    });

  const signup = async () => {
    try {
      await api.post("/signup", {
        fullName: values.fullName,
        emailID: values.emailID,
        password: values.password,
      });
      toast.success("Verification link sent to your email");
      navigate("/signin");
    } catch (err) {
      console.log("🚀 ~ file: signup.js:60 ~ signup ~ err:", err);
    }
  };

  useEffect(() => {
    document.title = "Signup - Suraksha Check";
  }, []);

  return (
    <div className="signup">
      <main>
        <h1>SIGN UP</h1>
        <input
          placeholder="Full Name"
          autoComplete={false}
          onChange={handleChange("fullName")}
          onBlur={handleBlur("fullName")}
          value={values.fullName}
        />
        {errors.fullName && touched.fullName && (
          <pre className="err">{errors.fullName}</pre>
        )}

        <input
          placeholder="Email ID"
          type="email"
          autoComplete={false}
          onChange={handleChange("emailID")}
          onBlur={handleBlur("emailID")}
          value={values.emailID}
        />
        {errors.emailID && touched.emailID && (
          <pre className="err">{errors.emailID}</pre>
        )}

        <input
          placeholder="Password"
          type="password"
          autoComplete={false}
          onChange={handleChange("password")}
          onBlur={handleBlur("password")}
          value={values.password}
        />
        {errors.password && touched.password && (
          <pre className="err">{errors.password}</pre>
        )}

        <input
          placeholder="Confirm Password"
          type="password"
          autoComplete={false}
          onChange={handleChange("rPassword")}
          onBlur={handleBlur("rPassword")}
          value={values.rPassword}
        />
        {errors.rPassword && touched.rPassword && (
          <pre className="err">{errors.rPassword}</pre>
        )}

        <div className="info">
          Already have a account? <Link to="/signin">SIGN IN</Link>
        </div>
        <Clickable className="btn" onClick={handleSubmit}>
          SIGN UP
        </Clickable>
      </main>
    </div>
  );
};

export default Signup;
