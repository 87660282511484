const Clickable = ({
  children,
  className,
  style = {},
  disabled,
  onClick = () => null,
}) => {
  return (
    <div
      className={(disabled ? "" : "clickable ") + className}
      onClick={disabled ? () => null : onClick}
      style={{
        opacity: disabled ? 0.5 : 1,
        ...style,
        cursor: disabled ? "default" : "pointer",
      }}
    >
      {children}
    </div>
  );
};

export default Clickable;
