import { useDispatch, useSelector } from "react-redux";
import MainWrapper from "../components/mainWrapper";
import { useEffect, useState } from "react";
import Clickable from "../components/clickable";
import useRazorpay from "react-razorpay";
import toast from "../functios/toast";
import images from "../constants/images";
import api from "../functios/api";
import { setCredits } from "../redux/slices";
import { useNavigate } from "react-router-dom";

const Credits = () => {
  const [cred, setCred] = useState(null);

  const credits = useSelector((s) => s.credits.value);
  const token = useSelector((s) => s.token.value);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [Razorpay] = useRazorpay();

  const prices = [500, 1000, 2000, 5000, 10000, 15000, 20000, 25000];

  const handlePayment = () => {
    if (!token) return navigate("/signin");

    const options = {
      key: "rzp_test_8LT2ObdHd0alEH",
      amount: parseFloat(cred) * 200,
      currency: "INR",
      name: "Suraksha Check",
      description: `Buy ${cred} credits`,
      image: images.logo,
      // order_id: "lund",
      handler: ({ razorpay_payment_id: paymentID }) => {
        buyCredtis(paymentID);
      },
      // prefill: {
      //   name: "Piyush Garg",
      //   email: "youremail@example.com",
      //   contact: "9999999999",
      // },
      theme: {
        color: "#e67e22",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const buyCredtis = async (paymentID) => {
    try {
      const { credits } = await api.post("/buy-credits", {
        paymentID,
        credits: cred,
      });
      dispatch(setCredits(credits));
      toast.success("Credits bougth successfully");
      setCred(null);
    } catch (err) {
      console.log("🚀 ~ file: credits.js:46 ~ buyCredtis ~ err:", err);
    }
  };

  useEffect(() => {
    document.title = "Credits - Suraksha Check";
  }, []);

  return (
    <div className="credits">
      <MainWrapper className="credits-wrapper">
        <main>
          <div className="my-credits">
            <span>Remaining Credits</span>
            {credits}
          </div>
          <h2>Buy Credits</h2>
          <input
            placeholder="Enter Credits (min: 500)"
            value={cred}
            onChange={(e) => {
              e = e.currentTarget.value;
              setCred(e);
            }}
          />
          <div className="prices">
            {prices.map((item) => (
              <Clickable
                className="price"
                onClick={() => setCred(item.toString())}
              >
                {item}
              </Clickable>
            ))}
          </div>
          <Clickable
            disabled={!cred || !cred.match(/^[1-9]\d*$/) || cred < 500}
            className="btn"
            onClick={handlePayment}
          >
            {cred && cred >= 500
              ? "Buy " + cred + " credits at " + parseFloat(cred) * 2 + " INR"
              : "Buy"}
          </Clickable>
        </main>
      </MainWrapper>
    </div>
  );
};

export default Credits;
